import axios from 'axios'
import paramsService from '@/services/http/params.service'
import utilsService from '@/services/utils/utils.service'

const { decoupageTerritorialeBaseUrl } = paramsService

export default {
  namespaced: true,
  state: {
    typesRoles: null,
    categoriesClient: null,
    modesReglement: null,
    listPays: utilsService.getListCountries(),
    listMois: [
      { name: 'Janvier' },
      { name: 'Février' },
      { name: 'Mars' },
      { name: 'Avril' },
      { name: 'Mai' },
    ],
    listAnnees: [
      { name: '2019' },
      { name: '2020' },
      { name: '2021' },
      { name: '2022' },
      { name: '2023' },
    ],
    currentSectorSelected: null,
  },
  getters: {
    getter_typesRoles(state, data) {
      return state.typesRoles
    },
    getter_categoriesClient(state) {
      return state.categoriesClient
    },
    getter_modesReglement(state) {
      return state.modesReglement
    },
    getter_currentSectorSelected(state) {
      return state.currentSectorSelected
    },
  },
  mutations: {
    setter_typesRoles(state, data) {
      // state.typesRoles = data
    },
    setter_categorieClient(state, data) {
      state.categoriesClient = data
    },
    setter_modesReglement(state, data) {
      state.categoriesClient = data
    },
    setter_currentSectorSelected(state, data) {
      state.currentSectorSelected = data
    },
  },
  actions: {
    async fetchTypesRoles({ commit, rootState, state }, data) {
      // if (state.typesRoles) return state.typesRoles
      const res = await paramsService.fetchTypesRoles(data)
      if (res.status) {
        commit('setter_typesRoles', res.data.roles)
      }
      return res
    },

    async fetchCategorieClient({ commit, rootState }, data) {
      const res = await paramsService.fetchCategorieClient(data)
      if (res.status) {
        commit('setter_categorieClient', res)
      }
      return res
    },
    async fetchModeReglements({ commit, rootState }, data) {
      const res = await paramsService.fetchModeReglementss(data)
      if (res.status) {
        commit('setter_modesReglement', res)
      }
      return res
    },
    async fetchIndicators({ commit, rootState }, data) {
      const res = await paramsService.fetchIndicators(data)
      return res
    },
    async checkIfTokenValid({ commit, rootState }, data) {
      const res = await paramsService.checkIfTokenValid(data)
      return res
    },
    async fetchModeFacturations({ commit, rootState }, data) {
      const res = await paramsService.fetchModeFacturations(data)
      return res
    },
    async fetchOrigineClients({ commit, rootState }, data) {
      const res = await paramsService.fetchOrigineClients(data)
      return res
    },
    async fetchCategorieFornisseurs({ commit, rootState }, data) {
      const res = await paramsService.fetchCategorieFornisseurs(data)
      return res
    },
    async fetchEquipeCommerciales({ commit, rootState }, data) {
      const res = await paramsService.fetchEquipeCommerciales(data)
      return res
    },
    async fetchRegleCommissionnements({ commit, rootState }, data) {
      const res = await paramsService.fetchRegleCommissionnements(
        data,
      )
      return res
    },
    async fetchTypeCoordonnees({ commit, rootState }, data) {
      const res = await paramsService.fetchTypeCoordonnees(data)
      return res
    },
    async fetchPhoneNetworks({ commit, rootState }, data) {
      const res = await paramsService.fetchPhoneNetworks(data)
      return res
    },
    async fetchPays({ commit, rootState }, data) {
      const res = await paramsService.fetchPays(data)
      return res
    },
    async fetchDevises({ commit, rootState }, data) {
      const res = await paramsService.fetchDevises(data)
      return res
    },
    async fetchCommercials({ commit, rootState }, data) {
      const res = await paramsService.fetchCommercials(data)
      return res
    },
    async fetchFormeJuridiques({ commit, rootState }, data) {
      const res = await paramsService.fetchFormeJuridiques(data)
      return res
    },
    async sendMailNousContacter({ commit, rootState }, data) {
      const res = await paramsService.sendMailNousContacter(data)
      return res
    },
    loadDepartments({ commit, rootState }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${decoupageTerritorialeBaseUrl}/departments`)
          .then(result => {
            resolve(result.data.departments)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    loadCommunes({ commit }, departement) {
      return new Promise((resolve, reject) => {
        let route = ''
        if (departement && departement.name) {
          route = `${decoupageTerritorialeBaseUrl}/departments/${departement.name}/towns`
        } else {
          route = `${decoupageTerritorialeBaseUrl}/towns`
        }
        axios
          .get(route)
          .then(result => {
            resolve(result.data.towns)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    loadArrondissements({ commit }, commune) {
      return new Promise((resolve, reject) => {
        let route = ''
        if (commune && commune.name) {
          route = `${decoupageTerritorialeBaseUrl}/towns/${commune.name}/districts`
        } else {
          route = `${decoupageTerritorialeBaseUrl}/districts`
        }
        axios
          .get(route)
          .then(result => {
            resolve(result.data.districts)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    loadNeighborhoods({ commit }, district) {
      return new Promise((resolve, reject) => {
        let route = ''
        if (district && district.name) {
          route = `${decoupageTerritorialeBaseUrl}/districts/${district.name}/neighborhoods`
        } else {
          // route = `${decoupageTerritorialeBaseUrl}/neighborhoods?page_size=5304`
          route = `${decoupageTerritorialeBaseUrl}/neighborhoods`
        }
        axios
          .get(route)
          .then(result => {
            const { neighborhoods } = result.data
            if (neighborhoods.data) resolve(neighborhoods.data)
            resolve(result.data.neighborhoods)
          })
          .catch(err => {
            reject(err)
          })
      })
    },

  },
}
