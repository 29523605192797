<template>
  <div class="color-band-gouv-bj ">
    <div class="green item" />
    <div class="yellow item" />
    <div class="red item" />
  </div>
</template>

<script>

export default {
  name: 'ColorBandGouvBj',
}
</script>
<style lang="scss" scoped>
.color-band-gouv-bj {
  height: 5px;
  display: flex;

  .green {
    background-color: green;
  }
  .yellow {
    background-color: yellow;
  }
  .red {
    background-color: red;
  }

  .item {
    height: 100%;
    width: calc(100% / 3);
  }
  
}
</style>
