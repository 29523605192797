import localstorageService from '@/services/localstorage/localstorage.service'

export default {
  namespaced: true,
  state: {
    show: false,
    isSolved: false,
    isCompleted: false,
  },
  getters: {
    getter_show(state) {
      return state.show
    },
  },
  mutations: {
    setter_show(state, data) {
      state.show = data
    },
    setter_isSolved(state, data) {
      state.isSolved = data
    },
    setter_isCompleted(state, data) {
      state.isCompleted = data
    },
    clearState(state, data) {
      state.isCompleted = false
      state.isSolved = false
    },
  },
  actions: {
    async show({ commit, state, rootState }, data) {
      commit('clearState', {})
      commit('setter_show', true)
      return new Promise(resolve => {
        const looper = () => {
          const { isCompleted, isSolved, show } = state
          setTimeout(() => {
            if (!show) resolve({ isSolved })
            if (isSolved) {
              commit('setter_show', false)
              resolve({ isSolved })
            } else {
              looper()
            }
          }, 0)
        }
        looper()
      })
    },
    async hide({ commit, rootState }, data) {
      commit('clearState', {})
      commit('setter_show', false)
    },
    async onSuccess({ commit, rootState }, data) {
      commit('setter_isCompleted', true)
      commit('setter_isSolved', true)
    },
    async onError({ commit, rootState }, data) {
      commit('setter_isCompleted', false)
      commit('setter_isSolved', false)
    },
  },
}
