<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <AppLogo />
        <!-- <vuexy-logo /> -->
        <!-- <h2 class="brand-text text-primary ml-1">
          Vuexy
        </h2> -->
      </b-link>
      <!-- /Brand logo-->

      <!-- Image-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center px-5 py-0"
      >
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img
            fluid
            :src="sideImg"
            alt="Login V2"
            class="img-fluid"
          />
        </div>
      </b-col>

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h2"
          >
            {{ appName }}
          </b-card-title>

          <!-- form -->
          <validation-observer
            ref="loginForm"
            v-slot="{ invalid }"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="login"
            >
              <!-- email -->
              <b-form-group
                label="Email de l'établissement"
                label-for="login-email"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder="Entrer votre email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Mot de passe</label>
                  <b-link href="/forgot-password">
                    <small>Mot de passe oublié?</small>
                  </b-link>
                </div>
                <validation-provider
                  v-slot="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Entrer votre mot de passe"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- <validation-provider
                #default="{ errors }"
                name="Password"
                vid="password"
                rules="required"
              >
                <div class="my-1">
                  <RecaptchaWidget
                    v-model="recaptchaResponse"
                    @onRecaptchaChange="onRecaptchaChange"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </div>
              </validation-provider> -->
              <!-- <ImagePuzzleSliderCaptcha/> -->
              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid"
              >
                <b-spinner
                  v-if="isLoading"
                  small
                />
                Se connecter
              </b-button>
              <div class="d-flex flex-row-reverse mt-2">
                <b-link href="/">
                  <small>Retourner sur la page d'accueil</small>
                </b-link>
              </div>
              <!-- <b-button
                class="mt-1 w-100"
                variant="outline-secondary"
                href="/"
              >
                <i class="feather icon-arrow-left" />
                Retourner sur la page d'accueil
              </b-button> -->
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
    <ColorBandGouvBj />
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  VBTooltip,
  BSpinner,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
// eslint-disable-next-line import/no-cycle
// eslint-disable-next-line import/no-cycle
import { $themeConfig } from '@themeConfig'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { AbilityBuilder } from '@casl/ability'
import { initialAbility } from '@/libs/acl/config'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import store from '@/store/index'
import localstorageService from '@/services/localstorage/localstorage.service'
import ColorBandGouvBj from '@/components/ColorBandGouvBj.vue'
import AppLogo from '@/components/AppLogo.vue'
import RecaptchaWidget from '@/components/RecaptchaWidget.vue'
import utilsService from '@/services/utils/utils.service'
import { mapActions } from 'vuex'

export default {
  page() {
    return {
      title: () => this.title,
      meta: [{ name: 'description', content: $themeConfig.app.description }],
    }
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    BSpinner,

    // eslint-disable-next-line vue/no-unused-components
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,

    ColorBandGouvBj,
    AppLogo,
    RecaptchaWidget,
  },
  mixins: [togglePasswordVisibility],
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app
    return {
      appName,
      appLogoImage,
    }
  },
  data() {
    return {
      title: 'Connexion',
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/image-stat-1.jpg'),

      // validation rules
      required,
      email,
      isLoading: false,
      recaptchaResponse: null,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    // imgUrl() {
    //   if (store.state.appConfig.layout.skin === 'dark') {
    //     // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    //     this.sideImg = require('@/assets/images/pages/login-default.png')
    //     return this.sideImg
    //   }
    //   return this.sideImg
    // },
  },
  mounted() {
    const { redirectMotive } = this.$route.params
    if (redirectMotive && redirectMotive === 'sessionExpired') {
      const errorMessage = 'Votre session a expiré. Veuillez vous reconnecter pour accéder à votre compte.'
      this.$toast({
        component: ToastificationContent,
        props: {
          title: errorMessage,
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  },
  methods: {
    ...mapActions('imagePuzzleSliderCaptcha', { showSliderCaptcha: 'show' }),
    login() {
      this.$refs.loginForm.validate().then(async success => {
        if (success) {
          this.isLoading = true

          // const sliderCaptchaResponse = await this.showSliderCaptcha()
          // const { isSolved } = sliderCaptchaResponse || {}
          // console.log('sliderCaptchaResponse', isSolved)
          // if (!isSolved) {
          //   this.isLoading = false
          //   return
          // }

          // ReCaptcha v3
          // (optional) Wait until recaptcha has been loaded.
          await this.$recaptchaLoaded()

          // Execute reCAPTCHA with action "login".
          const token = await this.$recaptcha('login')
          if (!token) {
            this.isLoading = false
            return
          }

          this.$store
            .dispatch('auth/login', {
              email: this.userEmail,
              password: this.password,
              token
            })
            .then(response => {
              // console.log('login response ================== ', response)

              if (response.data.token) {
                const { admin: userData, token } = response.data
                // console.log('userData: ', userData)

                localstorageService.setJwtToken(token)
                // localstorageService.setJwtRefreshToken(
                //   response.data.data.accessToken,
                // )
                const isSuperAdmin = userData.role && userData.role.code === 'super_admin'
                const isAgentMtca = userData.role && userData.role.code === 'agent_mtca'
                const isAdminEtablissement = userData.role && userData.role.code === 'promo_etablissement'
                const isAgentEtablissement = userData.role && userData.role.code === 'agent_etablissement'
                const isEtablissementUser = isAdminEtablissement || isAgentEtablissement
                const isCommissaire = userData.role && userData.role.code === 'commissaire'
                const permissionsEtabUser = [
                  {
                    action: 'read',
                    subject: 'statistiquesEtablissemnts',
                  },
                ]
                const permissionsAgentMtca = [
                  {
                    action: 'read',
                    subject: 'statistiquesEtablissemnts',
                  },
                  {
                    action: 'read',
                    subject: 'statistiquesFrontieresTerrestres',
                  },
                ]
                const permissionsSuperAdmin = [
                  {
                    action: 'create',
                    subject: 'faq',
                  },
                  {
                    action: 'read',
                    subject: 'statsTest',
                  },

                ]
                const permissions = [
                  ...initialAbility,
                  ...(
                    (userData.role && userData.role.permissions)
                    || []
                  ).filter(e => e.subject !== 'all'),

                  // TODO must be removed later
                  ...(isAgentMtca && permissionsAgentMtca || []),
                  ...((isEtablissementUser && permissionsEtabUser) || []),
                  ...((isSuperAdmin && permissionsSuperAdmin) || []),
                  // ...[
                  //   {
                  //     action: 'read',
                  //     subject: 'etablissement',
                  //   },
                  //   {
                  //     action: 'create',
                  //     subject: 'etablissement',
                  //   },
                  //   {
                  //     action: 'read',
                  //     subject: 'agent',
                  //   },
                  //   {
                  //     action: 'create',
                  //     subject: 'agent',
                  //   },
                  // ],
                ]
                localstorageService.setUserData(
                  {
                    ...userData,
                    role: { ...userData.role, permissions },
                    ability: permissions,
                  },
                )

                // return

                // redirect user to the page he was looking for before he was redirect to login page. If none, redirect to /
                const pathToRedirectToAfterLogin = localstorageService.getRouteToRedirectToAfterLogin()
                window.location = pathToRedirectToAfterLogin || '/'
                // window.location.reload();

                // const { can, rules } = new AbilityBuilder()

                // can('manage', 'all')
                // can('access', 'etablissement')
                // can('read', 'Home')
                // can('create', 'Contact')
                // permissions.forEach(el => {
                //   can(el.action, el.subject)
                // })
                // this.$ability.update(rules)

                // ? This is just for demo purpose as well.
                // ? Because we are showing eCommerce app's cart items count in navbar
                // this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', userData.extras.eCommerceCartItemsCount)

                // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
                // this.$router
                //   .replace(getHomeRouteForLoggedInUser(userData.role))
                //   .then(() => {
                //     this.$toast({
                //       component: ToastificationContent,
                //       position: 'top-right',
                //       props: {
                //         title: 'Bienvenue',
                //         icon: 'CheckIcon',
                //         variant: 'success',
                //         // text: 'Bienvenue !',
                //       },
                //     })
                //   })
              } else {
                this.isLoading = false
                const customError = {
                  email: ['Email or Password is Invalid'],
                }
                this.$refs.loginForm.setErrors(customError)
              }
            })
            .catch(error => {
              this.isLoading = false
              // console.log('AUTH ERROR')
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.message || 'Email or Password is Invalid',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        }
      })
    },

    onRecaptchaChange(recaptchaEvent) {
      if (recaptchaEvent && recaptchaEvent.type === 'success') {
        this.recaptchaResponse = recaptchaEvent
      } else {
        this.recaptchaResponse = null
      }
    },
    updateAbility() {
      const { can, rules } = new AbilityBuilder()

      if (user.role === 'admin') {
        can('manage', 'all')
      } else {
        can('read', 'all')
      }

      this.$ability.update(rules)
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
