<script>
import { VueRecaptcha } from 'vue-recaptcha'

export default {
  name: 'RecaptchaWidget',
  components: {
    VueRecaptcha,
  },
  props: {},
  data() {
    return {
      siteKey: '6Lf-u5YbAAAAAIcQ3s_3CSJ2DwTHjp-4q8cM_XSu',
    }
  },

  computed: {},
  watch: {
    submit: {
      immediate: false,
      async handler(val, old) {
        await this.onSubmit()
      },
    },
  },
  methods: {
    onError(error) {
      const data = { type: 'error', data: error }
      this.$emit('onRecaptchaChange', data)
      // console.log('Error happened:', error)
    },
    async onSuccess(token) {
      // console.log('Succeeded:', token)
      const data = { type: 'success', data: token }
      this.$emit('onRecaptchaChange', data)
    },
    onExpired() {
      const data = { type: 'expired', data: null }
      this.$emit('onRecaptchaChange', data)
      // console.log('Expired')
    },
  },
}
</script>

<template>
  <div class=" text-center d-flex justify-content-center mx-auto">
    <VueRecaptcha
    :language="'fr'"
      :sitekey="siteKey"
      :load-recaptcha-script="true"
      @verify="onSuccess"
      @expired="onExpired"
      @error="onError"
    />
  </div>
  <!-- end row -->
</template>

<style lang="scss" scoped></style>
