/** Convert a 2D array into a CSV string
 */
const arrayToCsv = (data)=>{
    return data.map(row =>
      row
      .map(String)  // convert every value to String
      .map(v => v.replaceAll('"', '""'))  // escape double colons
      .map(v => `"${v}"`)  // quote it
      .join(',')  // comma-separated
    ).join('\r\n');  // rows starting on new lines
  }

  /** Download contents as a file
 * Source: https://stackoverflow.com/questions/14964035/how-to-export-javascript-array-info-to-csv-on-client-side
 */
const downloadBlob = (content, filename, contentType) =>{
    // Create a blob
    var blob = new Blob([content], { type: contentType });
    var url = URL.createObjectURL(blob);
  
    // Create a link to download it
    var pom = document.createElement('a');
    pom.href = url;
    pom.setAttribute('download', filename);
    pom.click();
  }


const exportArrayAsCsv = (array, filename, contentType='text/csv;charset=utf-8;') => {
  const content = arrayToCsv(array)
  downloadBlob(content, filename, contentType)
}

module.exports = {exportArrayAsCsv}