/* eslint-disable class-methods-use-this */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-unused-vars */
/* eslint-disable global-require */

import { initialAbility } from '@/libs/acl/config'
import localstorageService from '../localstorage/localstorage.service'

import {
  countries, arrondissements, communes,
  departements, quartiers,
} from './constants'

const generateSlug = title => title
  .toLowerCase()
  .replace(':', '-')
  .replace('/', '-')
  .replace(/[\s|-]+/gi, '-')

// const countries = require('./countries-en.json')
// eslint-disable-next-line camelcase
const role_admin_etablissement = 'promo_etablissement'
// eslint-disable-next-line camelcase
const role_agent_etablissement = 'agent_etablissement'

// eslint-disable-next-line camelcase
const role_commissaire = 'commissaire'
// eslint-disable-next-line camelcase
const role_agent_police = 'agent_police'

// eslint-disable-next-line camelcase
const role_agent_mtca = 'agent_mtca'
const role_agent_ddtca = 'agent_ddtca'

// eslint-disable-next-line camelcase
const role_super_admin = 'super_admin'
export class UtilsService {
  countries = countries

  communes = communes

  departements = departements

  arrondissements = arrondissements

  quartiers = quartiers

  formGroups = [{
    id: 'aeroport',
      name: 'Aéroport',
    },
    {
      id: 'frontiere_terrestre',
      name: 'Frontières Terrestres',
    },
    // {
    //     id: 'visiteurs_internationaux',
    //     name: 'Visiteurs Internationaux',
    // },
    {
      id: 'autres_fiches_public',
      name: 'Autres fiches',
    },
    // {
    //     id: 'hotel',
    //     name: 'Hôtel',
    // },
    // {
    //     id: 'tour',
    //     name: 'Hôtel',
    // },
  ]

  formulaires = [
    // frontières ------------------------------------------
    {
      title: "Fiche de renseignement d'entrée des voyageurs aux postes frontaliers terrestres",
      featherIcon: 'ShieldIcon',
      canBeSHownOnDashboard: true,
      remixIcon: 'ri-bus-fill',
      auth: true,
      vueComponents: {
        form: 'FormEntreesFrontieresTerrestres',
        preview: 'PreviewEntreesFrontieresTerrestres',
        details: 'DetailsEntreesFrontieresTerrestres',
      },
      apiRoutePrefixe: 'forms-frontiere-entree-voyageurs',
      sector: '62bb7437044d4b2b17db2144',
      groupId: 'frontiere_terrestre',
      groupTextualInfos: {
        title: 'Je suis entré au benin par les frontières terrestres',
        description: 'Cette fiche permet...',
      },
      description: "Cette fiche permet de collecter les données des voyageurs à l'entrée des frontière terrestres",
      type: 'grand_public',
      sousType: 'frontiere',
      transit:'entree',
      resource: 'formulaireFrontiereEntree',
    },
    {
      title: 'Fiche de renseignement de sortie des voyageurs aux postes frontaliers terrestres',
      featherIcon: 'ShieldIcon',
      canBeSHownOnDashboard: true,
      remixIcon: 'ri-bus-fill',
      auth: false,
      vueComponents: {
        form: 'FormSortiesFrontieresTerrestres',
        preview: 'PreviewSortiesFrontieresTerrestres',
        details: 'DetailsSortiesFrontieresTerrestres',
      },
      apiRoutePrefixe: 'forms-frontiere-sortie-voyageurs',
      sector: '62bb7437044d4b2b17db2144',
      groupId: 'frontiere_terrestre',
      groupTextualInfos: {
        title: 'Je quitte le benin par les frontières terrestres',
        description: 'Cette fiche permet...',
      },
      description: 'Cette fiche permet de collecter les données des voyageurs à la sortie des frontière terrestres',
      type: 'grand_public',
      sousType: 'frontiere',
      transit:'sortie',
      resource: 'formulaireFrontiereSortie',
    },

    // aéroport ------------------------------------------

    // Formulaire entrée aéroport
    // {
    //   title: "Questionnaire de renseignements à l'entrée des aéroports du Bénin",
    //   vueComponents: {
    //     form: 'FormEntreeAeroport',
    //     preview: 'PreviewEntreeAeroport',
    //     details: 'DetailsEntreeAeroport',
    //   },
    //   apiRoutePrefixe: 'forms-aeroport-entrees',
    //   remixIcon: 'ri-flight-land-fill',
    //   auth: false,
    //   sector: '62bb7437044d4b2b17db2144',
    //   groupId: 'aeroport',
    //   groupTextualInfos: {
    //     title: "Je suis arrivé au benin par l'aéroport",
    //     description: 'Cette fiche permet...',
    //   },
    //   resource: 'formulaireAeroportEntree',
    //   type: 'grand_public',
    // },

    // Formulaire sortie aéroport
    // {
    //   title: 'Questionnaire de renseignements à la sortie des aéroports du Bénin',
    //   vueComponents: {
    //     form: 'FormSortieAeroport',
    //     preview: 'PreviewSortieAeroport',
    //     details: 'DetailsSortieAeroport',
    //   },
    //   apiRoutePrefixe: 'forms-aeroport-sorties',
    //   remixIcon: 'ri-flight-takeoff-fill',
    //   auth: false,
    //   sector: '62bb7437044d4b2b17db2144',
    //   groupId: 'aeroport',
    //   groupTextualInfos: {
    //     title: "Je quitte le benin par l'aéroport",
    //     description: 'Cette fiche permet...',
    //   },
    //   resource: 'formulaireAeroportSortie',
    //   type: 'grand_public',
    // },

    {
      title: "Fiche d'enregistrement des clients dans les hôtels",
      roleBasedTitle: {
        etablissementUserTitle: "Fiche d'enregistrement des clients de mon établissement",
      },
      vueComponents: {
        form: 'FormHotelClient',
        preview: 'PreviewHotelClient',
        details: 'DetailsHotelClient',
      },
      apiRoutePrefixe: 'forms-clients-hotels',
      featherIcon: 'HomeIcon',
      canBeSHownOnDashboard: true,
      remixIcon: 'ri-hotel-fill',
      auth: false,
      sector: '62ac4f0c3548c48f58636741',
      groupId: 'autres_fiches_public',
      groupTextualInfos: {
        title: 'Je suis dans un hôtel',
        description: 'Cette fiche permet...',
      },
      description: "Cette fiche permet de collecter les données sur les clients des établissements d'hébergement des voyageurs",
      resource: 'formulaireHotel',
      type: 'client',
    },
    {
      title: "Fiche statistique mensuelle sur l'exploitation des établissements d'hébergement",
      roleBasedTitle: {
        etablissementUserTitle: "Fiche statistique mensuelle sur l'exploitation de mon établissement",
      },
      vueComponents: {
        form: 'FormHotel',
        preview: 'PreviewHotel',
        details: 'DetailsHotel',
      },
      apiRoutePrefixe: 'forms-stats-hotels',
      featherIcon: 'HomeIcon',
      canBeSHownOnDashboard: true,
      remixIcon: 'ri-hotel-fill',
      auth: true,
      sector: '62ac4f0c3548c48f58636741',
      // groupId: 'hotel',
      groupTextualInfos: {
        title: 'Je suis dans un hôtel',
        description: 'Cette fiche permet...',
      },
      description: "Cette fiche permet de collecter les données sur le fonctionnement mensuel des établissements d'hébergement des voyageurs",
      resource: 'formulaireHotelMensuel',
      type: 'etablissement',
    },
    
    

    // fiches etablissements
    {
      title: 'Fiche statistique mensuelle des agences de voyage',
      description: "Cette fiche permet de collecter les données sur le fonctionnement mensuel des agences de voyage",
      vueComponents: {
        form: 'FormAgenceVoyage',
        preview: 'PreviewAgenceVoyage',
        details: 'DetailsAgenceVoyage',
      },
      apiRoutePrefixe: 'forms-stats-agences',
      image: require('@/assets/images/illustration/Globalization-pana.png'),
      featherIcon: 'MapIcon',
      canBeSHownOnDashboard: true,
      remixIcon: 'ri-plane-fill',
      auth: true,
      sector: '62ac481b3548c48f58636740',
      permissionsByRole: {
        read: [role_admin_etablissement, role_agent_etablissement],
        create: [role_admin_etablissement, role_agent_etablissement],
      },
      typeEts: ['agence_voyage'],
      resource: 'formulaireAgenceVoyageMensuel',
      type: 'etablissement',
    },
    {
      title: 'Fiche statistique mensuelle des agences de location de véhicules sans opérateur',
      description: "Cette fiche permet de collecter les données sur le fonctionnement mensuel des agences de location de véhicules sans opérateur",
      vueComponents: {
        form: 'FormLocation',
        preview: 'PreviewLocation',
        details: 'DetailsLocation',
      },
      apiRoutePrefixe: 'forms-stats-location-vehicule-sans-ops',
      featherIcon: 'TruckIcon',
      canBeSHownOnDashboard: true,
      remixIcon: 'ri-bus-fill',
      auth: true,
      sector: '62ac4f3b3548c48f58636743',
      type: 'etablissement',
      resource: 'formulaireLocationVehiculeSansOpMensuel',
    },
    {
      title: 'Fiche statistique mensuelle des établissements de restaurants, bars et débit de boissons',
      description: "Cette fiche permet de collecter les données sur le fonctionnement mensuel des établissements de restaurants, bars et débit de boissons",
      vueComponents: {
        form: 'FormRestauration',
        preview: 'PreviewRestauration',
        details: 'DetailsRestauration',
      },
      apiRoutePrefixe: 'forms-stats-restaus',
      featherIcon: 'DatabaseIcon',
      canBeSHownOnDashboard: true,
      remixIcon: 'ri-restaurant-fill',
      auth: true,
      sector: '62ac4f293548c48f58636742',
      resource: 'formulaireRestaurantMensuel',
      type: 'etablissement',
    },
    {
      title: 'Fiche statistique mensuelle des agences de transport de voyageurs',
      description: "Cette fiche permet de collecter les données sur le fonctionnement mensuel des agences de transport de voyageurs",
      vueComponents: {
        form: 'FormAgenceTransport',
        preview: 'PreviewAgenceTransport',
        details: 'DetailsAgenceTransport',
      },
      apiRoutePrefixe: 'forms-stats-transports',
      featherIcon: 'TruckIcon',
      canBeSHownOnDashboard: true,
      remixIcon: 'ri-bus-fill',
      auth: true,
      sector: '62ac4f3b3548c48f58636743',
      resource: 'formulaireTransportMensuel',
      type: 'etablissement',
    },
    {
      title: 'Fiche statistique mensuelle des services sportifs et récréatifs',
      description: "Cette fiche permet de collecter les données sur le fonctionnement mensuel des agences fournissant des services sportifs et récréatifs",
      remixIcon: 'ri-football-fill',
      auth: true,
      sector: '62bb7549044d4b2b17db2145',
      type: 'etablissement',
      resource: 'formulaireServiceSportifRecreatifMensuel',
      apiRoutePrefixe: 'forms-stats-sports',
      vueComponents: {
        form: 'FormAgenceSportive',
        preview: 'PreviewAgenceSportive',
        details: 'DetailsAgenceSportive',
      },
      canBeSHownOnDashboard: true,
    },
    {
      title: 'Fiche statistique mensuelle des services culturels',
      vueComponents: {
        form: 'FormActivitesCulturelles',
        preview: 'PreviewActivitesCulturelles',
        details: 'DetailsActivitesCulturelles',
      },
      remixIcon: 'ri-football-fill',
      auth: true,
      sector: '62bb7549044d4b2b17db2145',
      type: 'etablissement',
      resource: 'formulaireServiceCulturel',
      apiRoutePrefixe: 'forms-stats-cultures',
      vueComponents: {
        form: 'FormAgenceCulturelle',
        preview: 'PreviewAgenceCulturelle',
        details: 'DetailsAgenceCulturelle',
      },
      canBeSHownOnDashboard: true,
    },

    // Tourisme émetteur
    // {
    //   title: "Fiche d'enquête sur les visiteurs internationaux - frontières (Tourisme émetteur)",
    //   vueComponents: {
    //     form: 'FormFicheTourismeEmetteur',
    //     preview: 'PreviewFicheTourismeEmetteur',
    //     details: 'DetailsFicheTourismeEmetteur',
    //   },
    //   apiRoutePrefixe: 'forms-tourisme-emetteurs',
    //   remixIcon: 'ri-base-station-line',
    //   auth: false,
    //   sector: '62bb7437044d4b2b17db2144',
    //   groupId: 'autres_fiches_public',
    //   groupTextualInfos: {
    //     title: 'Je veux remplir la fiche sur les visiteurs internationaux - émetteur',
    //     description: 'Cette fiche permet...',
    //   },
    //   type: 'grand_public',
    //   resource: 'formulaireTourismeEmetteur',
    // },
    // Tourisme récepteur
    // {
    //   title: "Fiche d'enquête sur les visiteurs internationaux - frontières (Tourisme récepteur)",
    //   vueComponents: {
    //     form: 'FormFicheTourismeRecepteur',
    //     preview: 'PreviewFicheTourismeRecepteur',
    //     details: 'DetailsFicheTourismeRecepteur',
    //   },
    //   apiRoutePrefixe: 'forms-tourisme-recepteurs',
    //   remixIcon: 'ri-base-station-line',
    //   auth: false,
    //   sector: '62bb7437044d4b2b17db2144',
    //   groupId: 'autres_fiches_public',
    //   groupTextualInfos: {
    //     title: 'Je veux remplir la fiche sur les visiteurs internationaux - récepteur',
    //     description: 'Cette fiche permet...',
    //   },
    //   type: 'grand_public',
    //   resource: 'formulaireTourismeRecepteur',
    // },
    // Tourisme interne
    // {
    //   title: 'Fiche d\'enquête sur le tourisme interne',
    //   remixIcon: 'ri-bus-fill',
    //   auth: false,
    //   vueComponents: {
    //     form: 'FormFicheTourismeInterne',
    //     preview: 'PreviewFicheTourismeInterne',
    //     details: 'DetailsFicheTourismeInterne',
    //   },
    //   apiRoutePrefixe: 'forms-tourisme-internes',
    //   sector: '',
    //   groupId: 'autres_fiches_public',
    //   groupTextualInfos: {
    //     title: 'Je suis touriste à l\'intérieur du Bénin',
    //     description: 'Cette fiche permet...',
    //   },
    //   resource: 'formulaireTourismeInterne',
    //   type: 'grand_public',
    // },
  ]

  sex = [
    'Masculin', 'Féminin',
  ]

  constructor() {
    this.formulaires = this.formulaires
      .map((item, i) => {
        const slug = generateSlug(item.title)
        const params = {
          slug,
          title: item.title,
        }
        return {
          ...item,
          id: i,
          img: require('@/assets/images/illustration/sales.svg'),
          icon: 'ActivityIcon',
          route: 'formulaires-list',
          path: `/formulaires/list/${slug}`,
          matchExact: true,
          isStatsListDropDownLink: true,
          slug,
          params,
          createRouteProps: {
            name: 'formulaires-create',
            path: `/formulaires/create/${slug}`,
            params,
          },
          previewRouteProps: {
            name: 'formulaires-preview',
            path: `/formulaires/preview/${slug}`,
            params,
          },
        }
      })
      .sort((a, b) => (a.title.length > b.title.length ? a : b))
    // this.getListFormulaires()
  }

  getListFormulaires(data) {
    if (data && data.privateOnly) {
      return this.formulaires.filter(item => item.auth === true)
    }
    if (data && data.publicOnly) {
      return this.formulaires.filter(item => item.auth === false)
    }
    return this.formulaires
  }

  getCountries() {
    return this.countries
  }

  getSex() {
    return this.sex
  }

  getCommunes() {
    return this.communes
  }

  getDepartements() {
    return this.departements
  }

  getListFormulairesByGroup(data) {
    const groups = this.formGroups

    if (data && data.privateOnly) {
      return groups.map(group => {
        const formGroup = {
          ...group,
          items: this.formulaires.filter(e => e.groupId === group.id && e.auth === true),
        }
        return formGroup
      })
    }

    if (data && data.publicOnly) {
      return groups.map(group => {
        const formGroup = {
          ...group,
          items: this.formulaires.filter(e => e.groupId === group.id && e.auth === false),
        }
        return formGroup
      })
    }

    return groups.map(group => {
      const formGroup = {
        ...group,
        items: this.formulaires.filter(e => e.groupId === group.id),
      }
      return formGroup
    })
  }

  getListFormulairesForDashboard(data) {
    return this.formulaires.filter(item => item.canBeSHownOnDashboard)
  }

  getFormulaireBySlug(slug) {
    if (!slug) return ''
    const foundItem = this.formulaires.find(e => e.slug === slug)
    if (!foundItem) return null
    return foundItem
  }

  getFormPerTypeEtablissements() {
    const { nom } = this.etablissementDetails() || {}
    const foundItem = this.formulaires.find(e => e.typeEts && e.typeEts.includes('agence_voyage'))
    // console.log('nom', foundItem, this.userData)
    if (!foundItem) return null
    return foundItem
  }

  getFormulaireByModel(model) {
    if (!model) return ''
    const foundItem = this.formulaires.find(e => e.apiRoutePrefixe === model)
    if (!foundItem) return null
    return foundItem
  }

  getFormulaireTitleBySlug(slug) {
    const { isEtablissementUser, etablissementDetails, isSuperAdmin } = this.currentUserUtils()

    const appendEtablissementNameToTitle = input => {
      const { nomEtablissement: etsName } = etablissementDetails
      let formattedName = ''
      if (isEtablissementUser) formattedName = ` ( ${etsName} ) `
      return input + formattedName
    }

    const item = this.getFormulaireBySlug(slug)
    if (!item) return ''
    const { roleBasedTitle } = item
    if (roleBasedTitle) {
      const { etablissementUserTitle } = roleBasedTitle
      if (isEtablissementUser) return appendEtablissementNameToTitle(etablissementUserTitle || item.title)
    }
    return item.title
  }

  // eslint-disable-next-line class-methods-use-this
  getRandomInt(min = 10, max = 1000) {
    // eslint-disable-next-line no-param-reassign
    min = Math.ceil(min)
    // eslint-disable-next-line no-param-reassign
    max = Math.floor(max)
    return Math.floor(Math.random() * (max - min + 1)) + min
  }

  getHomePageFormulairesList() {
    return this.formulaires.map(item => ({
      ...item,
    }))
  }

  // eslint-disable-next-line class-methods-use-this
  getListYears(from, to) {
    const oldest = from || 1990
    const newest = to || new Date().getFullYear()
    const yearsDiff = newest - oldest
    const years = Array.from({ length: yearsDiff }, (v, i) => oldest + i + 1).sort((a, b) => b - a)
    // console.log('years', oldest, newest, yearsDiff, years)
    return years
  }

  // eslint-disable-next-line class-methods-use-this
  getListMonths(locale = 'fr') {
    return [...Array(12).keys()].map(key => new Date(0, key).toLocaleString('fr', { month: 'long' }).toString())
      .map(e => e.charAt(0).toUpperCase() + e.slice(1))
  }

  // eslint-disable-next-line class-methods-use-this
  getListCountries() {
    return countries
  }

  etablissementDetails() {
    return this.userData && this.userData.etablissement
  }

  get etablissementId() {
    return this.etablissementDetails() && this.etablissementDetails()._id
  }

  commissariatDetails() {
    return this.userData && this.userData.commissariatAssociated
  }

  get commissariatId() {
    return this.commissariatDetails() && this.commissariatDetails()._id
  }

  // Pour la faq
  get faqId() {
    return this.faqDetail() && this.faqDetail()._id
  }

  // eslint-disable-next-line class-methods-use-this
  get userData() {
    return localstorageService.getUserData()
  }

  get userRole() {
    return (this.userData && this.userData.role) || null
  }

  get userRoleId() {
    return (this.userRole && this.userRole._id) || null
  }

  get userPermissions() {
    const permissions = (this.userData && this.userData.role && this.userData.role.permissions) || []
    return [
      ...permissions,
      ...initialAbility,
    ]
  }

  get userId() {
    return (this.userData && this.userData._id) || null
  }

  isAdminEtablissement() {
    // eslint-disable-next-line camelcase
    return this.userRole && this.userRole.code === role_admin_etablissement
  }

  isAgentEtablissement() {
    // eslint-disable-next-line camelcase
    return this.userRole && this.userRole.code === role_agent_etablissement
  }

  isCommissaire() {
    // eslint-disable-next-line camelcase
    return this.userRole && this.userRole.code === role_commissaire
  }

  isAgentPolice() {
    // eslint-disable-next-line camelcase
    return this.userRole && this.userRole.code === role_agent_police
  }

  isAgentMtca() {
    // eslint-disable-next-line camelcase
    return this.userRole && this.userRole.code === role_agent_mtca
  }

  isAgentDdtca() {
    // eslint-disable-next-line camelcase
    return this.userRole && this.userRole.code === role_agent_ddtca
  }

  isSuperAdmin() {
    // eslint-disable-next-line camelcase
    return this.userRole && this.userRole.code === role_super_admin
  }

  currentUserUtils() {
    return {
      // user
      currentUserId: this.userId,
      isAuthenticated: this.userId !== null,
      permissions: this.userPermissions,
      userData: this.userData,

      // etablissement
      etablissementId: this.etablissementId,
      etablissementDetails: this.etablissementDetails(),

      commissariatId: this.commissariatId,
      commissariatDetails: this.commissariatDetails(),

      isAdminEtablissement: this.isAdminEtablissement(),
      isAgentEtablissement: this.isAgentEtablissement(),
      isEtablissementUser: this.isAdminEtablissement() || this.isAgentEtablissement(),
      
      // agent mtca
      isAgentMtca: this.isAgentMtca(),
      // agent ddtca
      isAgentDdtca: this.isAgentDdtca(),
      // super admin
      isSuperAdmin: this.isSuperAdmin(),


      // role
      userRole: this.userRole,
      userRoleId: this.userRoleId,

      // commissariat
      isCommissaire: this.isCommissaire(),
      isAgentPolice: this.isAgentPolice(),
      isCommissariatUser: this.isCommissaire() || this.isAgentPolice(),

      // to be removed
      countries: this.getCountries(),
      sexs: this.getSex(),
      communes: this.getCommunes(),
      departements: this.getDepartements(),

    }
  }

  countriesAndPlaces(params) {
    const { stringOnly } = params || {}
    const addNameKeyToObj = arr => arr.map(item => {
      if (typeof item === 'string') return { name: item }
      return item
    })

    if (!stringOnly) {
      return {
        countries: addNameKeyToObj(this.countries),
        departements: addNameKeyToObj(this.departements),
        communes: addNameKeyToObj(this.communes),
        arrondissements: addNameKeyToObj(this.arrondissements),
        quartiers: addNameKeyToObj(this.quartiers),
      }
    }

    return {
      countries: this.countries,
      departements: this.departements,
      communes: this.communes,
      arrondissements: this.arrondissements,
      quartiers: this.quartiers,
    }
  }

  turnToSnakeCase(val) {
    return val
      .toLowerCase()
      .replace(/[\s|-]+/gi, '_')
  }

  turnToCamelCase(val) {
    let words = val
    words = words.replace(/é/gi, 'e')
    words = words.split(' ')

    const first = words.shift()

    words = words.map(word => {
        const firstLetter = word[0]
        return firstLetter.toUpperCase() + word.slice(1)
    })

    const result = first + words.join('')
    return result
  }

  // eslint-disable-next-line class-methods-use-this
  getDayCountBetweenTwoDates(options) {
    const { dates } = options || {}
    if (!dates) { return 0 }
    const { first, second } = dates
    if (!first || !second) { return 0 }
    const oneDay = 24 * 60 * 60 * 1000 // hours*minutes*seconds*milliseconds
    const firstDate = new Date(first)
    const secondDate = new Date(second)
    const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay))
    return diffDays
  }

  calculatePercentage(val, total, toFixed) {
    const notValid = !val || !total || val === 0 || total === 0

    if (notValid) return 0
    let result = (val * 100) / (total)
    // console.log('calculatePercentage', val, total, result)
    if (toFixed) {
      result = (result).toFixed(toFixed)
    }
    return result
  }
}

// export an instance of the class
export default new UtilsService()
