const passwordValidators = input => {
  const regMinusLetter = /(?=.*?[a-z])/
  const regCapLetter = /(?=.*?[A-Z])/
  const regDigits = /(?=.*?[0-9])/
  const regSpecialChar = /(?=.*?[@#$%^&+=])/
  const regLength = /^(.*?).{8,}/

  const atLeastOneMinusLetter = regMinusLetter.test(input)
  const atLeastOneCapLetter = regCapLetter.test(input)
  const atLeastOneDigits = regDigits.test(input)
  const atLeastOneSpecialChar = regSpecialChar.test(input)
  const minimumLength = regLength.test(input)

  const isValid = atLeastOneMinusLetter
        && atLeastOneCapLetter 
        && atLeastOneDigits
        && atLeastOneSpecialChar
        && minimumLength

  return {
    atLeastOneMinusLetter,
    atLeastOneCapLetter,
    atLeastOneDigits,
    minimumLength,
    atLeastOneSpecialChar,
    isValid,
  }
}

export default passwordValidators
