import rolesService from '@/services/http/roles.service'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async fetchRoles({ rootState }, data) {
      const res = await rolesService.fetchRoles(data)
      return res
    },
    async addRoles({ rootState }, data) {
      const res = await usersService.addRoles(data)
      return res
    },
  },
}
