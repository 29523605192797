<script>
import { SliderCaptchaPlugin } from '@/libs/image-puzzle-slider-captcha/src/disk/slidercaptcha.custom.js'
import { mapActions, mapGetters } from 'vuex'

const defaultImage = require('@/assets/images/pages/eCommerce/27.png')

const fileToBase64 = (filename, filepath) => new Promise(resolve => {
  const file = new File([filename], filepath)
  const reader = new FileReader()
  // Read file content on file loaded event
  reader.onload = function (event) {
    console.log(event.target.result)
    resolve(event.target.result)
  }

  // Convert data to base64
  reader.readAsDataURL(file)
})
// fileToBase64('test.png',defaultImage)

export default {
  name: 'RecaptchaWidget',
  components: {},
  props: {},
  data() {
    return {
      sliderCaptchaInstance: null,
      showCaptchaModal: false,
      sliderCaptchaOptions: {
        id: 'captcha',
        repeatIcon: 'ri-refresh-line',
        sliderIcon: 'ri-arrow-right-line',
        loadingText: 'Chargement...',
        failedText: 'Veuillez réesayer svp !',
        barText: 'Glisser pour vérifier',
        // width: 500,
        //     localImage: () => {
        //     return fileToBase64('test.png',defaultImage);
        // },
      },
    }
  },

  computed: {
    ...mapGetters('imagePuzzleSliderCaptcha', {
      getter_showSliderCaptcha: 'getter_show',
    }),
  },
  watch: {
    submit: {
      immediate: false,
      async handler(val, old) {
        await this.onSubmit()
      },
    },
    getter_showSliderCaptcha: {
      immediate: false,
      handler(val, old) {
        // if(!val === false) this.showCaptchaModal();
      },
    },
  },
  mounted() {
    this.showRecaptchaModal()
  },
  methods: {
    ...mapActions('imagePuzzleSliderCaptcha', {
      action_hideSliderCaptcha: 'hide',
      action_onSuccess: 'onSuccess',
      action_onError: 'onError',

    }),
    showRecaptchaModal() {
      const onSuccess = () => {
        this.action_onSuccess()
        // this.sliderCaptchaInstance.reset();
      }
      const onFail = () => {
        console.log('fail')
        this.action_onError()
      }

      this.sliderCaptchaInstance = SliderCaptchaPlugin({
        ...this.sliderCaptchaOptions,
        onSuccess,
        onFail,
      })
    },
    hideCapthaModal() {
      this.showCaptchaModal = false
      this.sliderCaptchaInstance = null
      this.action_hideSliderCaptcha()
    },
    onError(error) {
      const data = { type: 'error', data: error }
      this.$emit('onRecaptchaChange', data)
      // console.log('Error happened:', error)
    },
    async onSuccess(token) {
      // console.log('Succeeded:', token)
      const data = { type: 'success', data: token }
      this.$emit('onRecaptchaChange', data)
    },
    onExpired() {
      const data = { type: 'expired', data: null }
      this.$emit('onRecaptchaChange', data)
      // console.log('Expired')
    },
  },
}
</script>

<template>
  <div id="slider-captcha">
    <div
      class="backdrop"
      @click="hideCapthaModal"
    />
    <div class="text-center d-flex justify-content-center mx-auto">
      <div class="slidercaptcha card">
        <div class="card-header">
          <span style="color: red"><strong>Déplacer la flèche verte afin de mettre la pièce manquante à sa place. </strong></span>
        </div>
        <div class="card-body">
          <div :id="sliderCaptchaOptions.id" />
        </div>
      </div>
    </div>
  </div>
  <!-- end row -->
</template>

<style lang="scss" scoped>
// @import '@slider-recaptcha/src/disk/slidercaptcha.min.css';
@import "~@core/../libs/image-puzzle-slider-captcha/src/disk/slidercaptcha.min.css";
#slider-captcha {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  .backdrop {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.493);
  }
  .sliderIcon {
    font-size: 2rem !important;
  }
}
</style>
