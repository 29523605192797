import secteursService from '@/services/http/secteurs.service'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async fetchSecteurs({ rootState }, data) {
      const res = await secteursService.fetchSecteurs(data)
      return res
    },
    async addSecteurs({ rootState }, data) {
      const res = await secteursService.addSecteurs(data)
      return res
    },
  },
}
