export default [
    {
      path: '/configs/permissions',
      name: 'permissions-index',
      component: () => import('@/views/cruds/Permissions/Index.vue'),
    },
    {
      path: '/configs/secteurs',
      name: 'secteurs-index',
      component: () => import('@/views/cruds/Secteurs/Index.vue'),
    },
    {
      path: '/configs/typeetablissements',
      name: 'typeetablissements-index',
      component: () => import('@/views/cruds/TypeEtablissements/Index.vue'),
    },
  ]
