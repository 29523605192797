<template>
  <!-- <b-link
    class="navbar-brand"
    to="/dashboard"
  > -->
  <a
    class="navbar-brand"
    @click="navigateToHome"
  >
    <b-img
      :height="height"
      :class="{'img-filter-blacknwhite':blacknwhite}"
      :src="require('@/assets/images/logo/logo_mtca.png')"
      alt="logo"
    /></a>
</template>

<script>
import { BImg, BLink } from 'bootstrap-vue'

export default {
  name: 'AppLogo',
  components: {
    BImg,
    BLink,
  },
  directives: {},
  props: {
    width: { type: Number, default: 100 },
    height: { type: Number, default: 50 },
    blacknwhite: { type: Boolean, default: false },
  },
  methods:{
navigateToHome(){
  window.location = "/"
}
  },
  computed: {
    logoImgProps() {
      return {
        blank: true,
        width: this.width,
        height: this.height,
      }
    },
  },
}
</script>
<style lang="scss" scoped></style>
