import Vue from 'vue'
import VueCompositionAPI from '@vue/composition-api'

import { Can } from '@casl/vue'
import i18n from '@/libs/i18n'
import VueProgressBar from 'vue-progressbar'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/toastification'
import '@/libs/vue-select'

import 'remixicon/fonts/remixicon.css'
import '@/assets/lineawesome/css/line-awesome.min.css'

import 'animate.css'
import * as VeeValidate from 'vee-validate'

import flatpickr from 'flatpickr'
import { French } from 'flatpickr/dist/l10n/fr'
import { exportArrayAsCsv } from '@/services/utils/csv.utils'
import moment from 'moment'
import VueMeta from 'vue-meta'
import { initialAbility } from '@/libs/acl/config'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import App from './App.vue'
import store from './store'
import router from './router'
// import shortUUID from 'short-uuid';

// ReCaptcha v3

Vue.use(VueReCaptcha, { siteKey: '6LfZ4KgkAAAAAP7zmXyXLuV4RQaG60en_NQCD9b1' })

Vue.use(VueMeta, {
  // The component option name that vue-meta looks for meta info on.
  keyName: 'page',
})

// moment js
// vue flatpick configs
flatpickr.setDefaults({
  dateFormat: 'd-m-Y', // default options here
})
flatpickr.localize(French)

const vueProgressBarOptions = {
  color: '#FCCD5D',
  failedColor: '#F3706A',
  thickness: '5px',
  transition: {
    speed: '0.2s',
    opacity: '0.6s',
    termination: 300,
  },
  autoRevert: true,
  location: 'top',
  inverse: false,
}

Vue.use(VueProgressBar, vueProgressBarOptions)

// CASL
Vue.component('Can', Can)

// Composition API
Vue.use(VueCompositionAPI)

const dictionary = {
  en: {
    messages: {
      alpha: () => 'Some English Message',
      required: () => 'The field is required !!!',
    },
  },
  fr: {
    messages: {
      required: field => 'Ce champ est requis',
      email: field => 'Ce champs requiert un email',
      alpha: field => 'Ce champs ne peut contenir que des lettres',
      strictlyNumber: field => 'Ce champs ne doit contenir que des nombres',
      strictlyString: field => 'Ce champs ne doit contenir que des lettres',
      beforeOrToday: field => 'La date ne peut pas dépasser la date du jour',
      afterToday: field => 'La date doit etre ultérieure à la date du jour',
      valideBirthdate: field => "L'age est compris entre 0 et 150 ans",
      length: (field, { length, max }) => {
        if (max) {
          return `Ce champs doit avoir entre ${length} et ${max} caratères`
        }
        return `Ce champs doit avoir ${length} caratères`
      },
      between: (field, { min, max }) => `La valeur est comprise entre ${min} et ${max}`,
    },
  },
}
VeeValidate.localize('fr', dictionary.fr)

// custom rules ------------------------------------------------
VeeValidate.extend('strictlyNumber', {
  // getMessage: field => 'Ce champs ne doit contenir que des nombres',
  validate: value => Number.isInteger(value),
})
VeeValidate.extend('strictlyString', {
  validate: value => !/\d/.test(value),
})
VeeValidate.extend('beforeOrToday', {
  validate: value => {
    const myMomentDate = moment(value, 'YYYY-MM-DD')
    const myMomentToday = moment()
    return !myMomentDate.isAfter(myMomentToday)
  },
})
VeeValidate.extend('afterToday', {
  validate: value => {
    const myMomentDate = moment(value, 'YYYY-MM-DD')
    const myMomentToday = moment()
    return myMomentDate.isAfter(myMomentToday, 'day')
  },
})
VeeValidate.extend('valideBirthdate', {
  validate: value => {
    const myMomentDate = moment(value, 'YYYY-MM-DD')
    const myMomentToday = moment()
    const duration = moment.duration(myMomentToday.diff(myMomentDate))
    const years = duration.asYears()
    if (years < 0 || years > 150) return false
    return true
  },
})
VeeValidate.extend('date_between', {
  computesRequired: true,
  validate: (value, params) => {
    const [start, end, strictCheck] = params
    value = new Date(value).getTime()
    if (start && end) {
      return (
        new Date(start).getTime() < value && value < new Date(end).getTime()
      )
    }
    if (start) {
      if (!strictCheck) {
        return new Date(start).getTime() <= value
      }
      return new Date(start).getTime() < value
    }
    if (end) {
      if (!strictCheck) {
        return value <= new Date(end).getTime()
      }
      return value < new Date(end).getTime()
    }
  },
  message: (field, params) => {
    const { 0: start, 1: end } = params
    const toLocaleDateString = date => new Date(date).toLocaleDateString({
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    })

    if (start && end) {
      return `La date doit être comprise entre ${toLocaleDateString(
        start,
      )} et ${toLocaleDateString(end)}`
    }
    if (start) {
      return `La date doit être ultérieur  au ${toLocaleDateMonthString(start)}`
    }
    if (end) {
      return `La date ne doit pas dépasser le ${toLocaleDateString(end)}`
    }
  },
})
// ------------------------------------------------------------

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

const mixins = {
  data() {
    return {
      todayDate: new Date().getTime(),
    }
  },
  methods: {
    formattedDate(annee, mois) {
      // Create a new Date object for the target month and year
      const date = new Date(annee, mois, 1);

      // Use the `toISOString()` method with a slice to get YYYY-MM-DD format
      return date.toISOString().slice(0, 10);
    },
    getFirstAndLastDaysForCurrMouth(year, month) {
      // start_day
      let curr_dM = this.formattedDate(year, month);
      console.log("curr_d :>> ", curr_dM);
      const today = new Date(curr_dM);
      let start_day = this.get_date(
        this.convertDateToLocalString(
          new Date(today.getFullYear(), today.getMonth(), 1)
        )
      )
      start_day = this.reverseDate(this.replaceSlashByDash(start_day))
      // end_day
      let end_day = this.get_date(
        this.convertDateToLocalString(
          new Date(today.getFullYear(), today.getMonth() + 1, 0)
        )
      )
      end_day = this.reverseDate(this.replaceSlashByDash(end_day))

      return [start_day, end_day]
    },
    convertDateToLocalString(date) {
      const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
      }
      let { locale } = i18n
      locale += `-${locale.toUpperCase()}`
      return new Date(date).toLocaleDateString(locale, options)
    },
    get_date(date) {
      if (date) {
        const array = date.split(' ')
        return array[0]
      }
    },
    reverseDate(date, separtor) {
      if (date) {
        const array = date.split(separtor || '-')
        array.reverse()
        return array.join(separtor || '-')
      }
      return null
    },

    replaceSlashByDash(date) {
      const array = date.split('/')
      return array.join('-')
    },
    formatCurrency(value) {
      // Create our number formatter.
      const formatter = new Intl.NumberFormat(i18n.locale, {
        style: 'currency',
        currency: 'XOF',
        // These options are needed to round to whole numbers if that's what you want.
        // minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        // maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      })
      return formatter.format(value)
    },
    customCloneDeep(obj) {
      return JSON.parse(JSON.stringify(obj))
    },
    calculateDifference(dateArrivee, dateDepart) {
      const date1 = new Date(dateArrivee)
      const date2 = new Date(dateDepart)
      // Calculate difference in milliseconds
      const timeDiff = date2.getTime() - date1.getTime()
      // Convert milliseconds to days and round down
      const differenceInDays = Math.floor(timeDiff / (1000 * 60 * 60 * 24))
      return differenceInDays
    },
    normalizeNumericValue(val) {
      if (!val) return 0
      if (Number.isNaN(parseInt(val))) return 0
      return Number.isNaN(val) ? 0 : val.toFixed(2)
    },
    removeinvalidMonth(monthList) {
      const currentMonth = moment().month()
      const newList = []
      monthList.forEach((item, index) => {
        if (index <= currentMonth) {
          newList.push(item)
        }
      })
      return newList
    },
    form_frontiere_entree_label_q17_q18(country) {
      if (country === 'Bénin')
        return [
          "Motif principal du voyage à l'étranger",
          "Durée de séjour à l'étranger"
        ]
      else
        return [
          "Motif principal du voyage au Bénin",
          "Durée de séjour au Bénin"
        ]
    },
    async logout() {
      await this.$store.dispatch('auth/logout', {}).then(res => {
        this.$ability.update(initialAbility)
        this.$router.replace({ name: 'home-public' })
      })
    },
    extractOriginalName(generatedName) {
      const nameParts = generatedName.split('.');
      const nameWithPrefixSuffix = nameParts[0];
      const extension = nameParts[1];
      const originalName = nameWithPrefixSuffix.slice(3, -7);
      return originalName;
    }
  },
  computed: {},
}

Vue.mixin(mixins)

// create custom durective for stats tables exports
Vue.directive('append-export-button', {
  bind(el, binding, vnode) {
    const onClickOnExportButtpn = data => {
      const computedWatchers = vnode.context._computedWatchers
      const parentData = vnode.context._data

      const tableColumns = vnode.context.tableColumns || []
      const tableData = (computedWatchers.getTableData || { value: [] }).value
      const columnsKeys = tableColumns.map(column => column.key)
      const columnsLabels = tableColumns.map(column => column.label)
      const AOA = []
      tableData.map(e => {
        const row = []
        columnsKeys.forEach((k, i) => {
          row[i] = e[k]
        })
        AOA.push(row)
      })

      AOA.unshift(columnsLabels)
      exportArrayAsCsv(AOA, 'export')
    }
    const exportComponent = document.createElement('div')
    const exportButton = document.createElement('button')
    exportButton.type = 'button'
    exportButton.setAttribute('class', 'btn float-right btn-primary')
    exportButton.innerText = 'Exporter en csv'
    exportButton.addEventListener('click', e => {
      onClickOnExportButtpn(e)
    })

    exportComponent.appendChild(exportButton)

    el.style.display = 'inline-block'
    el.style.width = '100%'
    el.style.textAlign = 'left'
    el.insertBefore(exportComponent, el.firstChild)
  },
})
// directive for number input field
Vue.directive('digits-only', {
  bind(el, binding, vnode) {
    const { length } = binding.value || {}
    const isNumeric = function (inputVal) {
      return /^[0-9]+$/.test(inputVal)
    }
    el.addEventListener('keydown', e => {
      const allowKey = key => [
        'Backspace',
        'Enter',
        'Shift',
        'Tab',
        'ArrowLeft',
        'ArrowRight',
      ].includes(key)
      if (!allowKey(e.key) && !isNumeric(e.key)) {
        e.preventDefault()
      }
      if (!allowKey(e.key) && length && e.target.value.length >= length) {
        e.preventDefault()
      }
    })
    el.addEventListener('keyup', e => {
      e.target.value = `${e.target.value}`
    })
  },
  update(el, binding, vnode, prevVnode) { },
})
// directive for phone number input field
Vue.directive('phonenumber-field', {
  bind(el, binding, vnode) {
    const { length } = binding.value || {}
    const isNumeric = function (inputVal) {
      return /^[\\(\\)\\+0-9]+$/.test(inputVal)
    }
    el.addEventListener('keydown', e => {
      const allowKey = key => [
        'Backspace',
        'Enter',
        'Shift',
        'Tab',
        'ArrowLeft',
        'ArrowRight',
      ].includes(key)
      if (!allowKey(e.key) && !isNumeric(e.key)) {
        e.preventDefault()
      }
      if (!allowKey(e.key) && length && e.target.value.length >= length) {
        e.preventDefault()
      }
    })
    el.addEventListener('keyup', e => {
      e.target.value = `${e.target.value}`
    })
  },
  update(el, binding, vnode, prevVnode) { },
})

// directive for name & name
Vue.directive('name', {
  bind(el, binding, vnode) {
    const { length } = binding.value || {}
    const isNumeric = function (inputVal) {
      return /^[a-zA-Z' éèï]*$/.test(inputVal)
    }
    el.addEventListener('keydown', e => {
      const allowKey = key => [
        'Backspace',
        'Enter',
        'Shift',
        'Tab',
        'ArrowLeft',
        'ArrowRight',
      ].includes(key)
      if (!allowKey(e.key) && !isNumeric(e.key)) {
        e.preventDefault()
      }
      if (!allowKey(e.key) && length && e.target.value.length >= length) {
        e.preventDefault()
      }
    })
    el.addEventListener('keyup', e => {
      e.target.value = `${e.target.value}`
    })
  },
  update(el, binding, vnode, prevVnode) { },
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')

