export default [
  // {
  //   path: '/users/search',
  //   name: 'users-search',
  //   component: () => import('@/views/pages/users/users-search/UserList.vue'),
  // },
  {
    path: '/users/list',
    name: 'users-list',
    component: () => import('@/views/apps/utilisateurs/administration/ListUtilisateurs.vue'),
    meta: {
      action: 'read',
      resource: 'Public',
    },
  },
  {
    path: '/users/:userId',
    name: 'users-details',
    component: () => import('@/views/apps/utilisateurs/administration/DetailsUtilisateur.vue'),
    meta: {
      action: 'read',
      resource: 'commissaire',
    },
  },
  // {
  //   path: '/users/search',
  //   name: 'users-create',
  //   component: () => import('@/views/pages/users/users-create/UserCreate.vue'),
  // },
]
