import Vue from 'vue'
// axios
import axios from 'axios'

export const jwtConfig = {
  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
}

const axiosInstance = axios.create({
  // baseURL,
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})

Vue.prototype.$http = axiosInstance

export default axiosInstance
