import { BaseService } from './base.service'

export class RoleService extends BaseService {
    constructor() {
        super()
    }

    fetchRoles(data) {
        const params = {}
        if (data && data.options) {
            params.options = data.options
        }
        return new Promise((resolve, reject) => {
            this.axiosInstance
                .get('/roles/list', { params })
                .then(response => resolve(response))
                .catch(error => reject(error))
        })
    }

    addRoles(role) {
        return new Promise((resolve, reject) => {
            this.axiosInstance
                .post('/roles/create', role)
                .then(response => resolve(response))
                .catch(error => reject(error))
        })
    }
}

export default new RoleService()