import { BaseService } from './base.service'

export class SecteurService extends BaseService {
  constructor() {
    super()
  }

  fetchSecteurs({ params }) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get('/secteurs/list', { params })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  addSecteurs(secteur) {
    // console.log('secteur: ',secteur)
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post('/secteurs/create', secteur)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
}

export default new SecteurService()