import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate, canNavigateToForm } from '@/libs/acl/routeProtection'
import {
  isUserLoggedIn,
  getUserData,
  getHomeRouteForLoggedInUser,
} from '@/auth/utils'

import utilsService from '@/services/utils/utils.service'
import { canFillForm as isFormFillingPeriod } from '@/helpers/manage-form-filling-period'
import localstorageService from '@/services/localstorage/localstorage.service'
import apps from './routes/apps'
import dashboard from './routes/dashboard'
import pages from './routes/pages'

import tourisme from './routes/tourisme'

import users from './routes/users'
import configurations from './routes/configurations'
import formsTourisme from './routes/forms-tourisme'
import notifications from './routes/notifications'
import faq from './routes/faq'

// remove remove push redirect errors
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) { return originalPush.call(this, location, onResolve, onReject) }
  return originalPush.call(this, location).catch(err => {
    if (VueRouter.isNavigationFailure(err)) {
      // resolve err
      return err
    }
    // rethrow error
    return Promise.reject(err)
  })
}

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home-public',
      component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: 'Home' */ '@/views/pages/home/Home.vue'),
      meta: {
        layout: 'default',
        resource: 'Public',
      },
    },
    ...users,
    ...tourisme,
    ...apps,
    ...dashboard,
    ...pages,
    ...configurations,
    ...formsTourisme,
    ...notifications,
    ...faq,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()
  const { isFormlaireRoute, action: routeAction, checkFormFillingPeriod } = to.meta

  const { slug, model } = to.params
  // console.log(slug,model);
  const selectedForm = slug ? utilsService.getFormulaireBySlug(slug) : utilsService.getFormulaireByModel(model)
  // console.log(selectedForm)
  let formulaireRoute = {
    ...to,
  }

  if (isFormlaireRoute && !selectedForm) {
    if (to.name !== 'home-public') return next({ name: 'home-public' })
  }

  if (selectedForm) formulaireRoute = { ...to, action: routeAction, resource: selectedForm.resource || 'Public' }
  // console.log('canNavigate(to)', formulaireRoute, canNavigateToForm(formulaireRoute))

  if ((isFormlaireRoute && !canNavigateToForm(formulaireRoute)) || (!isFormlaireRoute && !canNavigate(to))) {
    // Redirect to login if not logged in
    if (!isLoggedIn) {
      if (to.name !== 'auth-login') {
        localstorageService.setRouteToRedirectToAfterLogin(to.path)
        return next({ name: 'auth-login' })
      }
    }

    // If logged in => not authorized. Abilities need to come from the backend.
    if (to.name !== 'home-public') return next({ name: 'home-public' })
    // next({ name: 'misc-not-authorized' })
    // next({ name: 'auth-login' })
  }

  // // cHECK form filling period
  if (isFormlaireRoute && checkFormFillingPeriod && !isFormFillingPeriod) {
    // console.log('not')
    if (to.name !== 'home-public') {
      return next({
        name: 'home-public',
        params: {
          redirectMotive: 'OutOfFillingPeriod',
        },
      })
    }
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }
  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  const isLoggedIn = isUserLoggedIn()

  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }

  if (isLoggedIn) {
    localstorageService.removeRouteToRedirectToAfterLogin()
  }
})

export default router
