<template>
  <!-- <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size+'px'"
    :height="size + 'px'"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
:class="iconClass"  >
    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
    <circle cx="12" cy="7" r="4"></circle>
  </svg> -->
  <!-- <div > -->
  <i
    :class="iconClass"
    @click="onClick"
  />

  <!-- </div> -->
</template>
<script>
export default {
  props: {
    name: '',
    icon: '',
    size: {
      type: String,
      default: '14',
    },
  },
  computed: {
    iconClass() {
      const camelToHyphen = str => str
        .replace(/[A-Z]/g, letter => `-${letter.toLowerCase()}`)
        .split('-')
        .filter(e => e.length > 0)
        .reverse()
        .join('-')

      const feathersIconSubstitution = {
        'icon-cloud-download': 'icon-download-cloud',
        'icon-up-arrow': 'icon-arrow-up',
        'icon-off-eye': 'icon-eye-off',
        'icon-edit3': 'icon-edit',
        'icon-circle-help': 'icon-help-circle',
        'icon-out-log': 'icon-log-out',
        'icon-circle-x': 'icon-x-circle',
        'icon-right-chevron': 'icon-chevron-right',
        'icon-left-chevron': 'icon-chevron-left',
        'icon-triangle-alert': 'icon-alert-triangle',
      }
      let processedName = this.icon ? camelToHyphen(this.icon) : ""

      if (feathersIconSubstitution[processedName]) processedName = feathersIconSubstitution[processedName]

      let icon = ''
      const { size } = this
      icon += 'feather '
      icon += processedName
      if (size) {
        icon += ` h-${size}-px`
        icon += ` w-${size}-px`
      }
      // console.log(icon, this.size);
      return icon
      //   return "feather book-icon";
    },
  },
  methods: {
    onClick() {
      this.$emit('click', '')
    },
  },
}
</script>
