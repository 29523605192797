import localstorageService from '@/services/localstorage/localstorage.service'
import authService from '@/services/http/auth.service'

export default {
    namespaced: true,
    state: {
        callToRegister: { status: false, data: null },
        isAutoLoggedOut: false,
        user: localstorageService.getUserData(),
        isAuthenticated: localstorageService.getJwtToken() || false,
    },
    getters: {
        getter_isAuthenticated(state) {
            return state.isAuthenticated
        },
        getter_isAutoLoggedOut(state) {
            return state.isAutoLoggedOut
        },
        getter_callToRegister(state) {
            return state.callToRegister
        },
    },
    mutations: {
        setter_isAuthenticated(state, data) {
            state.isAuthenticated = data
        },
        setter_isAutoLoggedOut(state, data) {
            state.isAutoLoggedOut = data
        },
        setter_callToRegister(state, data) {
            data = data || {}
            state.callToRegister = { status: data.status || false, data: data.data }
        },
    },
    actions: {
        async login({ commit, rootState }, data) {
            const res = await authService.login(data)
            if (res.data.token) {
                const responseData = res.data
                if (responseData) {
                    const { token, admin: userData } = responseData
                    localstorageService.setJwtToken(token)
                    // localstorageService.setUserData(userData)
                    commit('setter_isAuthenticated', true)
                }
            }
            return res
        },
        async register({ commit, rootState }, data) {
            const res = await authService.register(data)
            return res
        },
        async registerEtablissement({ commit, rootState }, data) {
            const res = await authService.registerEtablissement(data)
            return res
        },

        async forgetPassword({ rootState }, data) {
            const res = await authService.forgetPassword(data)
            return res
        },

        async codeValidation({ rootState }, data) {
            const res = await authService.codeValidation(data)
            return res
        },

        async logout({ commit, rootState }, data) {
            const autoLogout = data && data.autoLogout
            if (autoLogout) commit('setter_isAutoLoggedOut', true)
            localstorageService.clearAuthData()
            commit('setter_isAuthenticated', false)
        },
        async resetPassword({ rootState }, data) {
            const res = await authService.resetPassword(data)
            return res
        },
        async setPasswordAgent({ rootState }, data) {
            const res = await authService.setPasswordAgent(data)
            return res
        },
    },
}