import { BaseService } from './base.service'

export class UtilsService extends BaseService {

  constructor() {
    super()
  }

  checkIFU({ numeroIfu }) {
    return new Promise((resolve, reject) => {
      this.nodejsAxiosInstance
        .get('https://ifubackend.impots.bj/api/default/searchByIFU/'+numeroIfu)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  sendMail(data) {
    return new Promise((resolve, reject) => {
      this.nodejsAxiosInstance
        .post('/send-mail', data)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

}

export default new UtilsService()