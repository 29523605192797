/**
 * Author:  Aristide Legba
 */

const isFormFillingPeriod = () => {
  const startDate = 1
  const endDate = 10

  const currentMonthDay = new Date().getDate()
  const canFill = currentMonthDay >= startDate && currentMonthDay <= endDate
  return canFill
}

// export const canFillForm = isFormFillingPeriod()
export const canFillForm = true
