import utilsService from '@/services/http/utils.service'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async checkIFU({ rootState }, data) {
      const res = await utilsService.checkIFU(data)
      return res
    },
    async sendMail({ rootState }, data) {
      const res = await utilsService.sendMail(data)
      return res
    },
  },
}
