<template>
  <div class="px-2">
    <div class="auth-inner py-2">
      <!-- Register v1 -->
      <!-- <b-card class="mb-0">
        <b-link class="brand-logo">
          <AppLogo />
        </b-link> -->
      <b-card class="mb-1">
        <!-- <b-link class="brand-logo">
          <AppLogo />
        </b-link> -->

        <h2>Inscription des Touristes</h2>
      </b-card>
      <!-- <b-card-text class="mb-2">
          Make your app management easy and fun!
        </b-card-text> -->

      <!-- form -->
      <b-card class="mb-0">
        <b-form
          class="auth-register-form mt-2"
          @submit.prevent="submitForm"
        >
          <!-- nom -->
          <b-form-group
            label="Nom"
            label-for="nom"
          >
            <b-form-input
              id="nom"
              v-model="form.nom"
              :state="$v.form.nom.$error ? false : null"
              name="nom"
            />
            <div v-if="$v.form.nom.$error">
              <small
                v-if="$v.form.nom.required.$invalid"
                class="text-danger"
              >Ce champ est requis</small>
            </div>
          </b-form-group>

          <!-- prenom -->
          <b-form-group
            label="Prenom"
            label-for="prenom"
          >
            <b-form-input
              id="prenom"
              v-model="form.prenom"
              :state="$v.form.prenom.$error ? false : null"
              name="prenom"
            />
            <div v-if="$v.form.prenom.$error">
              <small
                v-if="$v.form.prenom.required.$invalid"
                class="text-danger"
              >Ce champ est requis</small>
            </div>
          </b-form-group>

          <!-- email -->
          <b-form-group
            label="Email"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="form.email"
              :state="$v.form.email.$error ? false : null"
              name="email"
            />
            <div v-if="$v.form.email.$error">
              <small
                v-if="$v.form.email.required.$invalid"
                class="text-danger"
              >Ce champ est requis</small>
              <small
                v-if="$v.form.email.email.$invalid"
                class="text-danger"
              >Adresse email invalid</small>
            </div>
          </b-form-group>

          <!-- Telephone -->
          <b-form-group
            label="Telephone"
            label-for="telephone"
            type="number"
            :class="$v.form.telephone.$error ? 'is-invalid' : null"
          >
            <b-form-input
              id="form.telephone"
              v-model="form.telephone"
              v-phonenumber-field

              :state="$v.form.telephone.$error ? false : null"
              name="telephone"
            />
            <div v-if="$v.form.telephone.$error">
              <small
                v-if="$v.form.telephone.required.$invalid"
                class="text-danger"
              >Ce champ est requis</small>
            </div>
          </b-form-group>

          <!-- password -->
          <PasswordInputField v-model="form.password" />

          <!-- confirm password -->
          <b-form-group
            label-for="register-password"
            label="Confirmer le mot de passe"
          >
            <b-input-group
              class="input-group-merge"
              :class="$v.form.confirmPassword.$error ? 'is-invalid' : null"
            >
              <b-form-input
                id="register-password"
                v-model="form.confirmPassword"
                class="form-control-merge"
                :type="passwordFieldType"
                :state="$v.form.confirmPassword.$error ? false : null"
                name="register-password"
                placeholder="············"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="
                    passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
                  "
                  class="cursor-pointer"
                  @click="togglePasswordInputType"
                />
              </b-input-group-append>
            </b-input-group>
            <div v-if="$v.form.confirmPassword.$error">
              <small
                v-if="$v.form.confirmPassword.isValid.$invalid"
                class="text-danger"
              >Les mot de passe ne sont pas identiques</small>
            </div>
          </b-form-group>

          <div
            v-if="false"
            class="mb-1"
          >
            <!-- Styled -->
            <RecaptchaWidget @onRecaptchaChange="onRecaptchaChange" />
            <small
              v-if="$v.recaptchaResponse.isValid.$invalid"
              class="text-danger"
            >Veuillez resoudre le recaptcha pour continuer</small>
          </div>

          <!-- submit button -->
          <b-button
            variant="primary"
            block
            type="submit"
          >
            <b-spinner
              v-if="isLoading"
              small
            />
            S'inscrire
          </b-button>
        </b-form>
        <div class="p-2 d-flex justify-content-center">
          <span>Avez vous deja un compte ? </span>
          <b-link :to="{ name: 'auth-login' }">
            <span>Se Connecter</span>
          </b-link>
        </div>
      </b-card>
      <!-- /Register v1 -->
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BForm,
  BButton,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BFormFile,
  BSpinner,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import { mapActions, mapMutations, mapGetters } from 'vuex'
import vSelect from 'vue-select'
import AppLogo from '@/components/AppLogo.vue'
import RecaptchaWidget from '@/components/RecaptchaWidget.vue'
import PasswordInputField from '@/components/PasswordInputField.vue'

// store module and vuex utilities
import rolesStoreModule from '@/store/roles'

import {
  registerStoreModule,
  unregisterStoreModule,
} from '@/helpers/vuex-utils'

export default {
  setup() {
    const requiredStoreModules = [
      { path: 'roles', module: rolesStoreModule },
    ]
    // Register module
    registerStoreModule(requiredStoreModules)

    return { requiredStoreModules, $v: useVuelidate({ $lazy: true }) }
  },
  components: {
    // BSV
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BFormFile,
    // validations
    AppLogo,
    vSelect,
    RecaptchaWidget,
    BSpinner,
    PasswordInputField,
  },
  props: {
    inputDatas: {
      type: Object,
      default: () => ({
        nom: '',
        prenom: '',
        email: '',
        telephone: '',
        password: '',
        confirmPassword: '',
        role: '',
      }),
    },
  },
  data() {
    return {
      form: {
        nom: '',
        prenom: '',
        email: '',
        telephone: '',
        password: '',
        confirmPassword: '',
        role: '',
      },
      recaptchaResponse: null,
      passwordFieldType: 'password',
      isLoading: false,
    }
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules)
  },
  computed: {
    ...mapGetters('auth', {
      isAuthenticated: 'getter_isAuthenticated',
    }),
  },
  watch: {
    inputDatas: {
      immediate: true,
      handler(val, old) {
        if (val) {
          this.form = { ...this.form, ...val }
        }
      },
    },
  },

  validations() {
    return {
      form: {
        nom: { required },
        prenom: { required },
        email: { required, email },
        password: { required },
        telephone: { required },
        confirmPassword: {
          isValid: val => val && val === this.form.password,
        },
      },

      // recaptchaResponse: { isValid: (val) => val && val.type === "success" },
      isLoading: false,
    }
  },

  methods: {
    ...mapMutations('auth', {
      callUserToRegister: 'setter_callToRegister',
    }),
    ...mapActions('auth', {
      register: 'register',
    }),
    ...mapActions('roles', {
      action_fetchRoles: 'fetchRoles',
    }),

    ...mapActions('imagePuzzleSliderCaptcha', { showSliderCaptcha: 'show' }),
    togglePasswordInputType() {
      // eslint-disable-next-line no-unused-expressions
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
    },
    onRecaptchaChange(recaptchaEvent) {
      if (recaptchaEvent && recaptchaEvent.type === 'success') {
        this.recaptchaResponse = recaptchaEvent
      } else {
        this.recaptchaResponse = null
      }
    },
    async submitForm() {
      const isFormCorrect = await this.$v.$validate()
      if (this.isLoading) return
      if (!isFormCorrect) return

      this.isLoading = true
      const sliderCaptchaResponse = await this.showSliderCaptcha()
      const { isSolved } = sliderCaptchaResponse || {}
      console.log('sliderCaptchaResponse', isSolved)
      if (!isSolved) {
        this.isLoading = false
        return
      }
      const data = {
        firstname: this.form.nom,
        lastname: this.form.prenom,
        email: this.form.email,
        password: this.form.password,
        role: this.form.role,
      }
      this.register(data)
        .then(result => {
          this.isLoading = false
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Inscription reussie',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.callUserToRegister({ status: false, data: null })

          this.$router.replace({ name: 'home-public' })
        })
        .catch(err => {
          this.isLoading = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.message || 'Une erreur est survenue',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    getRoleGrandPublic() {
      const data = { options: { code: 'grand_public' } }
      this.action_fetchRoles(data)
        .then(res => {
          // console.log('res', res)
          if (res && res.data && res.data.resources) {
            // console.log('role', res.data.resources[0]._id)
            this.form.role = res.data.resources[0]._id
          } else {
            // console.log('errooooooooooooooooooooooooooor')
          }
        })
        .catch(err => {
          // console.log(err)
        })
    },
  },
  mounted() {
    this.getRoleGrandPublic()
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
